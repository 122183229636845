<template>
  <component :is="clientData === null ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientData === null && !loading"
    >
      <h4 class="alert-heading">
        Erro ao recuperar os dados
      </h4>
      <div class="alert-body">
        Não encontramos os dados deste usuário. Voltar para
        <b-link
          class="alert-link"
          :to="{ name: 'clients-list'}"
        >
          a lista de usuários.
        </b-link>
      </div>
    </b-alert>

    <!-- User Info: Input Fields -->
    <b-form
      v-if="clientData && !loading"
    >
      <b-row class="mb-1">
        <b-col>
          <h4>{{ clientData.profile.name ? clientData.profile.name : 'Nome do Cliente' }}</h4>
        </b-col>
        <b-col>
          <b-button
            class="float-right"
            variant="success"
            @click="updateClient"
          >
            Gravar
          </b-button>
        </b-col>
      </b-row>

      <b-row>

        <b-col>
          <b-row>
            <b-col
              cols="12"
              md="2"
            >
              <b-media class="mb-2">
                <template #aside>
                  <b-avatar
                    ref="previewEl"
                    :src="clientData.profile.logo"
                    :text="avatarText(clientData.profile.name)"
                    size="100px"
                    rounded
                  />
                </template>
              </b-media>

              <div class="d-flex flex-wrap mb-1">
                <b-button
                  variant="primary"
                  class="px-1"
                  @click="$refs.refInputEl.$el.childNodes[0].click()"
                >
                  <b-form-file
                    ref="refInputEl"
                    v-model="newFile"
                    class="d-none"
                    @input="inputImageRenderer"
                  />
                  <feather-icon
                    icon="EditIcon"
                    class="d-inline"
                  />
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="ml-1 px-1"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="d-inline"
                  />
                </b-button>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="10"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Nome"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="clientData.profile.name"
                      :state="validation.profile.name.state"
                    />
                    <b-form-invalid-feedback>
                      {{ validation.profile.name.feedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="clientData.email"
                      type="email"
                      :state="validation.email.state"
                    />
                    <b-form-invalid-feedback>
                      {{ validation.email.feedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Telefone"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="clientData.profile.phone"
                      v-mask="'(##) #####-####'"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="CNPJ"
                    label-for="cnpj"
                  >
                    <b-form-input
                      id="cnpj"
                      v-model="clientData.profile.cnpj"
                      v-mask="'##.###.###/####-##'"
                      :state="validation.profile.cnpj.state"
                    />
                    <b-form-invalid-feedback>
                      {{ validation.profile.cnpj.feedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Razao Social"
                    label-for="razao"
                  >
                    <b-form-input
                      id="razao"
                      v-model="clientData.profile.razao_social"
                      :state="validation.profile.razao_social.state"
                    />
                    <b-form-invalid-feedback>
                      {{ validation.profile.razao_social.feedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Nome do responsavel"
                    label-for="responsavel"
                  >
                    <b-form-input
                      id="responsavel"
                      v-model="clientData.profile.nome_responsavel"
                      :state="validation.profile.nome_responsavel.state"
                    />
                    <b-form-invalid-feedback>
                      {{ validation.profile.nome_responsavel.feedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Slug do URL"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="clientData.profile.slug"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

          </b-row>
        </b-col>

      </b-row>
      <b-row>

        <b-col
          cols="12"
        >
          <label for="description">Descrição</label>
          <b-form-textarea
            id="description"
            v-model="clientData.profile.description"
            placeholder="Descrição"
            rows="3"
            max-rows="8"
            no-auto-shrink
          />
        </b-col>

      </b-row>

      <b-row class="mt-1">
        <b-col>
          <h6>Endereço</h6>
        </b-col>
      </b-row>
      <b-row>

        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            label="Logradouro"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="clientData.profile.address"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Número"
            label-for="address_number"
          >
            <b-form-input
              id="address_number"
              v-model="clientData.profile.address_number"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Complemento"
            label-for="address_complement"
          >
            <b-form-input
              id="address_complement"
              v-model="clientData.profile.address_complement"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="CEP"
            label-for="zip_code"
          >
            <b-form-input
              id="zip_code"
              v-model="clientData.profile.zip_code"
              v-mask="'#####-###'"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            label="Cidade"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="clientData.profile.city"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="UF"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="clientData.profile.state"
              v-mask="'AA'"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="my-1">
        <b-col>
          <h5>Perguntas de cadastro obrigatórias</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.nome"
              name="q1-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Nome do cliente
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.documento"
              name="q2-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Número de documento do cliente
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.tipo"
              name="q3-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Tipo de documento do cliente
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.nascimento"
              name="q4-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Data de nascimento do cliente
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.telefone"
              name="q5-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Telefone do cliente
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.secundo_telefone"
              name="q6-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Segundo telefone do cliente
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.nome_contato_emergencia"
              name="q7-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Nome do contato de emergência
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.telefone_contato_emergencia"
              name="q8-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Telefone do contato de emergência
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.endereco"
              name="q9-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Endereço
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.parq"
              name="q10-switch"
              switch
            />
            <p
              class="mb-0"
            >
              PARQ
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.termo_responsabilidade"
              name="q11-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Termo de responsabilidade
            </p>
          </div>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="clientData.mandatory.cadastro_de_dados_lgpd"
              name="q3-switch"
              switch
            />
            <p
              class="mb-0"
            >
              Cadastro de dados LGPD
            </p>
          </div>
        </b-col>
      </b-row> -->
    </b-form>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BFormGroup, BFormInput, BMedia, BButton,
  BRow, BCol, BForm, BAvatar, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BFormFile,
} from 'bootstrap-vue'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BMedia,
    BButton,
    BRow,
    BCol,
    BForm,
    BAvatar,
    BFormTextarea,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    BFormFile,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      newFile: null,
      validation: {
        email: {
          value: null,
          feedback: '',
        },
        profile: {
          name: {
            value: null,
            feedback: '',
          },
          cnpj: {
            value: null,
            feedback: '',
          },
          razao_social: {
            value: null,
            feedback: '',
          },
          nome_responsavel: {
            value: null,
            feedback: '',
          },
        },
      },
      validationBase: {
        email: {
          value: null,
          feedback: '',
        },
        profile: {
          name: {
            value: null,
            feedback: '',
          },
          cnpj: {
            value: null,
            feedback: '',
          },
          razao_social: {
            value: null,
            feedback: '',
          },
          nome_responsavel: {
            value: null,
            feedback: '',
          },
        },
      },
      clientData: null,
      loading: true,
      emptyProfile: {
        logo: '',
        phone: '',
        name: '',
        zip_code: '',
        address: '',
        address_number: '',
        address_complement: '',
        city: '',
        state: '',
        cnpj: '',
        razao_social: '',
        nome_responsavel: '',
      },
      emptyQuestions: {
        nome: true,
        documento: false,
        tipo: false,
        nascimento: false,
        telefone: false,
        secundo_telefone: false,
        nome_contato_emergencia: false,
        telefone_contato_emergencia: false,
        endereco: false,
        parq: false,
        termo_responsabilidade: false,
        cadastro_de_dados_lgpd: false,
      },
      emptyClient: {
        first_name: '',
        last_name: '',
        email: '',
      },
    }
  },
  created() {
    if (this.userId !== -1) {
      this.$store.dispatch('clients/getClientById', this.userId).then(() => {
        this.clientData = this.$store.state.clients.clientData
        if (this.clientData.profile === null) {
          this.clientData.profile = { ...this.emptyProfile }
        }
        if (this.clientData.mandatory === null) {
          this.clientData.mandatory = { ...this.emptyQuestions }
        }
        this.loading = false
        this.previewEl = ref(null)
      })
    } else {
      this.clientData = this.emptyClient
      this.clientData.profile = { ...this.emptyProfile }
      this.clientData.mandatory = { ...this.emptyQuestions }
      this.loading = false
      this.previewEl = ref(null)
    }
  },
  methods: {
    updateClient() {
      this.$store.dispatch('clients/updateClient', this.clientData)
        .then(response => {
          this.validation = JSON.parse(JSON.stringify(this.validationBase))
          this.clientData.id = response.data.id
          this.updated = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Cliente',
              icon: 'UserCheckIcon',
              variant: 'success',
              text: response.status === 200 ? 'Cliente atualizado' : 'Cliente criado',
            },
          })
          this.$router.push({ name: 'clients-list' })
        })
        .catch(error => {
          this.validation = JSON.parse(JSON.stringify(this.validationBase))
          /* eslint-disable */
          for (const [k, v] of Object.entries(error.response.data)) {
            if (Array.isArray(v)) {
              this.validation[k] = {
                state: false,
                feedback: v[0],
              }
            } else {
              for (const [k2, v2] of Object.entries(v)) {
                this.validation[k][k2] = {
                  state: false,
                  feedback: v2[0],
                }
              }
            }
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Cliente',
              icon: 'UserXIcon',
              variant: 'danger',
              text: 'Não conseguimos gravar esses dados. Por favor verificar.',
            },
          })
        })
    },
    inputImageRenderer() {
      const file = this.newFile
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.clientData.profile.logo = reader.result
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
  },
  setup() {

    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
